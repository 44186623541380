import React, { Component } from "react";
import { graphql } from "gatsby";

import PubLayout from "../components/pubLayout";
import Pub from "../components/Pub/index.js";

class PubPage extends Component {
  render() {
    const data = this.props.data;

    return (
      <PubLayout
        meta={{
          metaDescription: data.wpPub.PubData.metaDescription,
          metaTitle: data.wpPub.PubData.metaTitle,
          ogDescription: data.wpPub.PubData.metaDescription,
          ogTitle: data.wpPub.PubData.metaTitle,
          twitterDescription: data.wpPub.PubData.metaDescription,
          twitterTitle: data.wpPub.PubData.metaTitle,
          twitterImage: {
            uri: data.wpPub.PubData.featuredImage,
          },
          ogImage: {
            uri: data.wpPub.PubData.featuredImage,
          },
        }}
        path={this.props.pageContext.pagePath}
        title={""}
        themeOptions={data.themeOptions}
      >
        <Pub data={data.wpPub.PubData} />
      </PubLayout>
    );
  }
}

export const query = graphql`
  query($id: String!) {
    wpPub(id: { eq: $id }) {
      id
      PubData {
        aboutUsLong
        aboutUsShort
        address1
        address2
        area
        backgroundColor
        brand
        clientReference
        crmId
        displayLocation
        email
        externalContactUrl
        externalHomepageUrl
        facebookHandle
        facilityAccommodation
        facilityBeerGarden
        facilityDogFriendly
        facilityFood
        facilityFreeParking
        facilityFamilyFriendly
        facilityWifi
        facilityGreatWalks
        facilityAcceptingGiftCards
        facilityWheelchairAccessible
        facilityByTheSea
        facilityEvChargingNearby
        facilityBusinessStays
        featuredImage
        localFile {
          id
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 1350
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
        }
        featuredVideoId
        fieldGroupName
        googlePlaceId
        hasOwnWebsite
        housenamePossesive
        housenameShort
        instagramHandle
        latitude
        longitude
        managerForename
        managerSurname
        metaDescription
        metaTitle
        name
        ogDescription
        ogTitle
        postcode
        pubOoh
        pubAlert
        pubClosed
        region
        roomBookingActive
        roomBookingLink
        stayWithUsFeatured
        suppress
        tableBookingActive
        tableBookingLink
        telephone
        timesJson
        town
        twitterDescription
        twitterHandle
        twitterTitle
        url
        roomBookingActive
        roomBookingLink
        tableBookingMessage
        tableBookingCode
        tableBookingLink
        tableBookingActive
        contentBlocks {
          startDate
          endDate
          hidden
          content
          fieldGroupName
          image
          title
          links {
            fieldGroupName
            linkText
            linkUrl
          }
        }
        galleryImages {
          fieldGroupName
          showInPubsGallery
          image
        }
        newsBlocks {
          hidden
          content
          fieldGroupName
          image
          title
          links {
            fieldGroupName
            linkText
            linkUrl
          }
        }
        airshipUrl
      }
    }
    allWp {
      nodes {
        themeOptions {
          accommodationActive
          roomBookingCTAText
          roomBookingImage
          roomBookingImageText
          roomBookingLink
          tableBookingAccountId
          tableBookingActive
          tableBookingBrandId
          tableBookingCTAText
          tableBookingImage
          tableBookingImageText
          tableBookingRestaurantId
          ThemeOptions {
            siteLogo {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export default PubPage;
