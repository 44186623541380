/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import CssBaseline from '@material-ui/core/CssBaseline';

import Meta from "./Meta";
import './layout.scss';
import { Provider } from 'react-redux';
import { store } from '../app/store';
import CookieModal from './CookieModal';

const PubLayout = (props) => {
  function renderMain() {
      return props.children;
  }

  return (
    <>
      <Provider store={store}>
        <CssBaseline />
          <style>{"html,body{max-width:100%;overflow-x:hidden;}body{position:relative}img{max-width:100%;}.gatsby-image-wrapper{position:relative;}.gatsby-image-wrapper picture{position:absolute;top:0;right:0;bottom:0;left:0;}.mb-0{margin-bottom: -1px !important;"}</style>
        <Meta
            meta={props.meta}
            title={props.title}
            path={props.path}
        />
        <CookieModal />
        { renderMain() }
      </Provider>
    </>
  )
}

PubLayout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  container: PropTypes.string
}

export default PubLayout
